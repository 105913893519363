import { useHttp } from '@/plugins/http/src/index'
import { fromResp, PageQueryParams, PageResponse, toRequest } from '@/common/page-data'
import { download } from '@/utils/download'
import { t } from '@/config/locale'
import axios from 'axios'
import _ from 'lodash'

export interface FinanceOrderQueryParams extends PageQueryParams {
  typeName?: string
}
interface QueryPruchaseListParams {
  companyNameBuyer?: string
  poNo?: string
  orderNo?: string
  excludePoNoList?: any[]
  financingProductId?: string | number
  companyCodeFunder?: string | number
}

interface QueryGoodsListParams {
  pageNum: number
  pageSize: number
  purchaseOrderId: string
  itemCode?: string
  itemDescription?: string
}

interface calculationParams {
  companyCodeFunder: string
  financingProductId: number
  arpItemDTOList?: any[]
  poItemDTOList?: any[]
}

interface QueryInvoiceListParams {
  pageNum: number
  pageSize: number
  companyNameBuyer?: string
  invoiceCode?: string
  invoiceNo?: string
}

const base = '/api/air8-financial-transaction/financeOrder/client'

// 列表
export function queryFinanceOrderList (params: FinanceOrderQueryParams) {
  const { http } = useHttp()
  return fromResp(http.post<any[]>(`${base}/pageList`, toRequest({ ...params, isOperation: true })))
}

export function downloadExcelForFinanceOrder (params: any) {
  return download(`${base}/excel/export`, toRequest({ ...params, isOperation: true }), 'post')
}

export function downloadCsvForFinanceOrder (params: any) {
  return download(`${base}/csv/export`, toRequest({ ...params, isOperation: true }), 'post')
}

// 撤回
export function revocation (id: string) {
  const { http } = useHttp()
  return http.post<any>(`${base}/revocation?id=${id}`)
}

// 获取融资产品下拉框选项
export function queryFinanceProduct (params = {}) {
  const { http } = useHttp()
  return http.post<any>(`${base}/availableFinanceProductList`, params)
}

// 获取资金方下拉框选项
export function queryFunderOptions (financingProductId: number) {
  const { http } = useHttp()
  return http.post<any>(`${base}/availableFinanceFunder`, { financingProductId })
}

// 采购/应收装运前 采购订单列表
export function queryPurhaseList (params: QueryPruchaseListParams) {
  const { http } = useHttp()
  return http.post<any>(`${base}/poList`, params)
}

// 采购/应收装运前 下拉搜索查找采购订单
export function queryPurhaseNoList (params: QueryPruchaseListParams) {
  const { http } = useHttp()
  return http.post<any>(`${base}/poListSimple`, params)
}

// 货品列表
export function queryGoodsList (params: QueryGoodsListParams) {
  const { http } = useHttp()
  return http.post<any>(`${base}/poItemList`, params)
}

// 试算
export function calculation (params: calculationParams) {
  const { http } = useHttp()
  return http.post<any>(`${base}/trialCalculation`, params).then(data => data.items)
}

// 银行信息
export function queryBankList (params: any) {
  const { http } = useHttp()
  return http.get<any>('/api/air8-company/clientend/company/accountByCurrencyList', { params })
}

// 新增=======TODO=====
export function createOrder (params: any) {
  const { http } = useHttp()
  return http.post<any>(`${base}/createOrder`, params)
}

// 装运后 单据列表
export function queryInvoiceList (params: QueryInvoiceListParams) {
  const { http } = useHttp()
  return http.post<any>(`${base}/invoiceList`, params)
}

// 获取买方列表
export function queryBuyerList (params: any) {
  const { http } = useHttp()
  params.buyerName = params.name || ''
  return http.post<any>(`${base}/availableBuye`, params).then(data => data.map((b: any) => ({
    name: b.buyerName,
    code: b.buyerCode,
    baseRateType: _.get(b, 'baseRateType')
  })))
}

// 融资订单详情 基本信息======TODO=====
export function queryBaseDetail (id: string) {
  const { http } = useHttp()
  return http.post<any>(`${base}/detail?id=${id}`)
}

// 融资订单详情 采购订单or单据
export function queryPurchaseOrInvoiceDetail (id: string) {
  const { http } = useHttp()
  return http.post<any>(`${base}/financingProduct?id=${id}`)
}

// 融资订单详情 审批信息
export function queryApproveList (id: string) {
  const { http } = useHttp()
  return http.post<any>(`${base}/financingOrderApproveList/${id}`)
}

// 修改 融资产品详情
export function queryProductDetail (id: string) {
  const { http } = useHttp()
  return http.post<any>(`${base}/detailForEdit?id=${id}`)
}

export function detailAttachmentForEdit (id: string) {
  const { http } = useHttp()
  return http.get<any>(`${base}/detailAttachmentForEdit/${id}`)
}

// 修改提交
export function editOrder (params: any) {
  const { http } = useHttp()
  return http.post<any>(`${base}/editOrder`, params)
}

// 确权
export function rightConfirmation (id: string) {
  const { http } = useHttp()
  return http.post<any>(`${base}/rightConfirmation?id=${id}`)
}

// 批量下载
export function downLoadAll (list: string[]) {
  download('/api/air8-company/sysAttachment/downloadBatch', { keys: list, fileName: t('page.finance.finance-order.download_name') })
}
export function downUploadFileAll (list: any, batchFileTitle: string) {
  const params = Array.isArray(list) ? { keys: list, fileName: batchFileTitle } : list
  download('api/air8-company/sysAttachment/downloadBatch', params)
}

export function downLoadAllList (list: string[]) {
  download('api/air8-company/sysAttachment/batchDownloadDmsAndS3', { files: list, fileName: t('page.finance.finance-order.download_name') })
}

export function queryLoanInfo (id: any) {
  const { http } = useHttp()
  return http.post<any>(`/api/air8-financial-transaction/financeOrder/client/fundingInfo/${id}`)
}

export function queryLoanAccountInfo (id: any) {
  const { http } = useHttp()
  return http.post<any>(`/api/air8-financial-transaction/financeOrder/client/fundingAccountInfos/${id}`)
}

export function queryRepayInfo (id: any) {
  const { http } = useHttp()
  return http.post<any>('/api/air8-financial-transaction/financeOrder/client/repaymentInfo', {}, { params: { id } })
}

// 获取DDR协议
export function queryDDR (params: any) {
  const { http } = useHttp()
  return http.post<any>('/api/air8-contract/client/config/queryConfigBatchList', params)
}

// 查看DDR协议
export function previewDDR (params: any) {
  const { http } = useHttp()
  return http.post<any>('/api/air8-financial-transaction/contract/client/preview', params)
}

// 预览arp协议
export function previewArp (objectKey: string) {
  const { http } = useHttp()
  http.get('/api/air8-company/sysAttachment/getUrl', { params: { objectKey, preview: true } }).then(data => {
    window.open(data)
  })
}

// 根据预期付款日获取最大融资比例
export function getMaxFinanceProportion (params: any) {
  const { http } = useHttp()
  return http.post<any>('/api/air8-financial-transaction/product/pricing/ByProductId', params)
}
// 单据关联附件查询
export function getdetailAttachment (params: any) {
  const { http } = useHttp()
  return http.post<any>('/api/air8-financial-transaction/financeOrder/client/detailAttachment', params)
}

export async function getInterestCountRate () {
  let res = [] as any
  await axios.get('https://markets.newyorkfed.org/api/rates/all/latest.json', {
    responseType: 'json',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      charset: 'utf-8'
    }
  }).then((result: any) => {
    res = _.chain(_.get(result.data, 'refRates'))
      .filter((k: any) => _.get(k, 'effectiveDate'))
      .map((item: any) => {
        _.set(item, '_time', new Date(_.get(item, 'effectiveDate')).getTime())
        return item
      })
      .maxBy('_time')
      .value()
  })
  return res
}
